import type {Environment} from './environment.model';
import {baseEnvironment} from './environment.model';

/* eslint-disable no-template-curly-in-string */
export const environment: Environment = {
    ...baseEnvironment,
    build: '2826800155',
    release: '2826800155-release',
    environment: '{{MHP_ENVIRONMENT}}',
    api: '{{MHP_API}}',
    tenant: 'porsche',
};
