import React from 'react';
import {useLocation} from 'react-router-dom'
import {
    useMediaQuery,
    useTheme,
    Box,
    Button,
    Divider,
    Link,
    List,
    ListItem,
    Menu,
    styled,
    SvgIcon,
    Avatar
} from '@mui/material';
import {environment} from "../environments/environment.prod"
import {useTranslation} from 'react-i18next';
import logo from "../assets/logo.png";
import logoPlaceholder from "../assets/logoPlaceholder.png";
import {ReactComponent as HomeIcon} from "../assets/assets-porsche/icon/menu/home.svg"
import {ReactComponent as ServiceIcon} from "../assets/assets-porsche/icon/menu/all-services.svg"
import {ReactComponent as ProfileIcon} from "../assets/assets-porsche/icon/menu/profile.svg"
import {ReactComponent as SettingIcon} from "../assets/assets-porsche/icon/menu/settings.svg"
import arrowDown from "../assets/assets-porsche/arrow-head-down.webp"
import {useGetUser} from "../proxy/api/user-info-controller/user-info-controller";
import DrawerMenu from './DrawerMenu';


const UserInfoContainer = styled('div')`
          border-radius: 0px;
          position: absolute;
          right: 30px;
          display: flex;
          align-self: center;
    `
;
const StyledPopoverMenu = styled(Menu)`
  width: 273px;
  padding: 10px;
`

const StyledUsername = styled('span')`
  align-self: center;
  font-size: 16px;
`;

const ArrowDown = styled('div')`
  align-self: center;
`;

const StyledMenuItem = styled(Button)(({theme}) => ` 
   margin-right:30px;
   color:${theme.palette.secondary.main};
   '&:hover': {
        color: ${theme.palette.primary.main}
    }
`);
const StyledHeader = styled(Box)(({theme}) => `
  display: flex;
  height: 100px;
  position: relative;
  justify-content: ${useMediaQuery(theme.breakpoints.down('md')) ? 'right': 'center' };
  width: 100%;
  background-color:${theme.palette.background.paper};
`);
const StyledLogo = styled('img')`
  height: 70px;
  width: 110px;
  position: absolute;
  left: 30px;
  align-self: center;
`;
const StyledMenu = styled('div')`
  width: 65%; //must be 796px
  flex-direction: row;
  align-self: center;
`;


const Header = () => {
    const {t} = useTranslation();
    const {tenant} = environment;
    const user = useGetUser();
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'))
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const currentPathname = useLocation().pathname;
    const hoverHandler = (event) => {
        event.target.className.includes('Button') ?
            event.target.children[0].style.fill = theme.palette.primary.main
            : event.target.style.fill = theme.palette.primary.main;
        if (event.target.className.includes('List')) {
            event.target.children[0].children[0].style.fill = theme.palette.primary.main;
            event.target.children[0].style.color = theme.palette.primary.main;
        }
    }
    const leavingHoverHandler = (event) => {
        event.target.className.includes('Button') ?
            event.target.children[0].style.fill = theme.palette.secondary.main
            : event.target.style.fill = theme.palette.secondary.main;
        if (event.target.className.includes('List')) {
            event.target.children[0].children[0].style.fill = theme.palette.secondary.main;
            event.target.children[0].style.color = theme.palette.secondary.main;
        }
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <StyledHeader>

            <StyledLogo
                src={tenant === 'porsche' ? logo : logoPlaceholder}
                alt={t('title')}/>
            {currentPathname !== '/' && !isMatch &&
                <StyledMenu>
                    <StyledMenuItem onMouseEnter={hoverHandler}
                                    onMouseLeave={leavingHoverHandler}
                                    sx={{"&:hover": {backgroundColor: "transparent", color: 'primary.main'}}}
                                    href='/home'>
                        <SvgIcon color='secondary' sx={{"&:hover": {color: 'primary.main'}}}>
                            <HomeIcon/>

                        </SvgIcon>                        &nbsp;Home</StyledMenuItem>
                    <StyledMenuItem onMouseEnter={hoverHandler}
                                    onMouseLeave={leavingHoverHandler}
                                    sx={{"&:hover": {backgroundColor: "transparent", color: 'primary.main'}}}
                                    href='/services'>
                        <SvgIcon color='secondary' sx={{"&:hover": {color: 'primary.main'}}}>
                            <ServiceIcon/>

                        </SvgIcon>                        &nbsp;All services</StyledMenuItem>
                </StyledMenu>

            }
            {currentPathname !== '/' && !isMatch &&
                <UserInfoContainer>
                    <StyledUsername>{user.data?.name}</StyledUsername>
                    <Avatar sx={{margin:'16px'}}>IMG</Avatar>
                    <ArrowDown onClick={handleClick}
                    ><img src={arrowDown} alt='arrow'/>
                    </ArrowDown>
                    <StyledPopoverMenu sx={{marginRight: '20px', marginTop: '40px'}}
                                       anchorEl={anchorEl}
                                       id="account-menu"
                                       open={open}
                                       onClose={handleClose}
                                       PaperProps={{
                                           elevation: 0,
                                       }}
                                       transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                       anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <List sx={{width: '207px'}}>
                            <ListItem sx={{height: '49px'}} onMouseEnter={hoverHandler}
                                      onMouseLeave={leavingHoverHandler}>
                                <Link href='/profile' color='secondary' sx={{
                                    display: 'flex',
                                    textDecoration: 'none',
                                    "&:hover": {backgroundColor: "transparent", color: 'primary.main'}
                                }}>
                                    <SvgIcon sx={{
                                        color: 'secondary.main',
                                        alignSelf: 'end',
                                        "&:hover": {color: 'primary.main'}
                                    }}>
                                        <ProfileIcon/>

                                    </SvgIcon>                        &nbsp;Profile</Link>
                            </ListItem>
                            <ListItem sx={{height: '49px'}} onMouseEnter={hoverHandler}
                                      onMouseLeave={leavingHoverHandler}>
                                <Link href='/settings' color='secondary' sx={{
                                    display: 'flex',
                                    textDecoration: 'none',
                                    "&:hover": {backgroundColor: "transparent", color: 'primary.main'}
                                }}>
                                    <SvgIcon sx={{
                                        color: 'secondary.main',
                                        alignSelf: 'end',
                                        "&:hover": {color: 'primary.main'}
                                    }}>
                                        <SettingIcon/>
                                    </SvgIcon>
                                    &nbsp;Settings</Link>
                            </ListItem>
                            <Divider></Divider>
                            <ListItem sx={{height: '49px'}}>
                                <Link href='/legalsAndImprint' color='secondary' sx={{
                                    display: 'flex',
                                    textDecoration: 'none',
                                    "&:hover": {backgroundColor: "transparent", color: 'primary.main'}
                                }}>
                                    &nbsp;Legas and Imprint </Link>
                            </ListItem>
                        </List>
                    </StyledPopoverMenu>
                </UserInfoContainer>
            }
            {currentPathname !== '/' && isMatch &&
                <DrawerMenu></DrawerMenu>}
        </StyledHeader>
    );
}

export default Header;

