import {Navigate, Route, Routes} from 'react-router-dom';
import Home from '../pages/Home';
import LandingPage from "../pages/LandingPage";
import Legals from '../pages/Legals';
import Profile from '../pages/Profile';
import Services from '../pages/Services';
import Settings from '../pages/Settings';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/home" element={<Home/>}/>
            <Route path="/services" element={<Services/>}/>
            <Route path="/settings" element={<Settings/>}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/legalsAndImprint" element={<Legals/>}/>
            <Route path="/" element={<LandingPage/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
};
