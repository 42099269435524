import React, {FC} from 'react';
import {styled} from "@mui/material";
import {SizeMe} from 'react-sizeme';

const Wrapper = styled('div')<BaseWidgetProps>(({theme, width, height}) => `
  position: relative;
  grid-column-start: span ${width};
  grid-row: auto;
  height: ${height}px;
`);

const StyledDeleteButton = styled('div')(({theme}) => `
  position: absolute;
  top:-11px;
  right:-10px;
  width: 24px;
  height: 24px;
  padding: 11px 5px;
  opacity: 0.8;
  border-radius: 50%;
  background-color:${theme.palette.primary.main}
`);

const StyledRectangle = styled('div')`
  width: 14px;
  height: 2px;
  background-color: white;
`;

interface BaseWidgetProps {
    width: number;
    height: number;
    editMode: boolean;
}

const BaseWidget: FC<BaseWidgetProps> = ({editMode, height, width, children}) => {
    return (
        <SizeMe>{({size}) => <Wrapper width={width}
                                      height={width === 1 ? (size.width || 0) * height : (size.width || 0) / width * height - (15 / width)}
                                      editMode={editMode}>
            {editMode &&
                <StyledDeleteButton><StyledRectangle></StyledRectangle></StyledDeleteButton>}
            {children}
        </Wrapper>}</SizeMe>
    );
}

export default BaseWidget;
