import {Link, styled} from '@mui/material';
import React, {FunctionComponent} from 'react';
import BaseWidget from "./BaseWidget";
import {LinkWidgetConfigDTO} from "../proxy/models";

const Item = styled("div")(
    ({theme}) => `
      display: flex;
      flex-direction: column;
      height: 100%;
      font-weight: 600;
      padding: 12px 13px 8px 12px;
      background-color:${theme.palette.background.paper}; 
`);

const StyledPlaceholder = styled('div')(({theme}) => `
  font-size: 36px;
  height: inherit;
  font-weight: 600;
  line-height: 1.33;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.palette.text.secondary};
`);

const StyledAnchor = styled(Link)(({theme}) => `
  color: ${theme.palette.text.primary};
`);

const StyledText = styled('div')`
  display: flex;
  justify-content: flex-start;
`

interface Props {
    width: number;
    height: number;
    editmode: boolean;
    config: LinkWidgetConfigDTO; // TODO: add ACL in form of a mapper service
}

const LinkWidget: FunctionComponent<Props> = ({config: {title, url}, editmode, height, width}) =>
    <BaseWidget width={width} height={height} editMode={editmode}>
        <Item>
            <StyledPlaceholder>{width}:{height}</StyledPlaceholder>
            <StyledText>
                <StyledAnchor href={url} underline='none'>
                    <span>{title}</span>
                </StyledAnchor>
            </StyledText>
        </Item>
    </BaseWidget>

export default LinkWidget;
