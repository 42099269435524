/**
 * Generated by orval v6.9.2 🍺
 * Do not edit manually.
 * Workforce API
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from 'react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  TenantConfig,
  GetTenantConfigParams
} from '../../models'
import { customAxios } from '../../../lib/axios'

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getTenantConfig = (
    params: GetTenantConfigParams,
 signal?: AbortSignal
) => {
      return customAxios<TenantConfig>(
      {url: `/api/user/v1/tenant`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetTenantConfigQueryKey = (params: GetTenantConfigParams,) => [`/api/user/v1/tenant`, ...(params ? [params]: [])];

    
export type GetTenantConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantConfig>>>
export type GetTenantConfigQueryError = unknown

export const useGetTenantConfig = <TData = Awaited<ReturnType<typeof getTenantConfig>>, TError = unknown>(
 params: GetTenantConfigParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantConfig>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTenantConfigQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantConfig>>> = ({ signal }) => getTenantConfig(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTenantConfig>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

