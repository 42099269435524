import React from "react";
import ReactDOM from 'react-dom';
import App from './App';
import './lib/i18n'

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
export {};
