/**
 * Generated by orval v6.9.2 🍺
 * Do not edit manually.
 * Workforce API
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from 'react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CurrentUserResponse
} from '../../models'
import { customAxios } from '../../../lib/axios'

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getUser = (
    
 signal?: AbortSignal
) => {
      return customAxios<CurrentUserResponse>(
      {url: `/api/user/v1/current-user`, method: 'get', signal
    },
      );
    }
  

export const getGetUserQueryKey = () => [`/api/user/v1/current-user`];

    
export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserQueryError = unknown

export const useGetUser = <TData = Awaited<ReturnType<typeof getUser>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) => getUser(signal);

  const query = useQuery<Awaited<ReturnType<typeof getUser>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

