import {porschePalette} from './porschePalette'

import { createTheme } from "@mui/material";
import '../porschTheme.less'

export const theme = createTheme({
    palette: porschePalette,
    typography: {
        fontFamily: [
            'Porsche'
        ].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 992,
            lg: 1200,
            xl: 1439,
        },
    },
    components: {
        // Name of the component ⚛️
        MuiContainer:{
            defaultProps: {
                maxWidth:false
            }
        },
        MuiButtonBase: {
            defaultProps: {
                // The default props to change
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
    },
});

