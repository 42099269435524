import React from 'react';
import {styled} from '@mui/material';
import sortWidgetsIcon from "../assets/assets-porsche/icon-light-theme-arrange.png";
import {useGetUser} from "../proxy/api/user-info-controller/user-info-controller";

const Wrapper = styled('div')`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const StyledName = styled('h1')`
  font-size: 24;
  margin: 32px 0;
  font-weight: 600;
`;

const StyledIcon = styled('div')`
  width: 24px;
  height: 24px;
`;

const SecondaryHeader = ({showActions, setShowActions}) => {
    const user = useGetUser();
    return <Wrapper>
        {user.isFetched && <StyledName>Hello {user.data?.givenName}</StyledName>}
        <StyledIcon>
            <img src={sortWidgetsIcon} onClick={() => setShowActions(!showActions)} alt="sort-widgets-icon"/>
        </StyledIcon>
    </Wrapper>
};


export default SecondaryHeader;

