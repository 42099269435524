import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {environment} from "../environments";

const { api } = environment;

function authRequestInterceptor(config: AxiosRequestConfig) {
    if (!config) {
        config = {};
    }
    if (!config.headers) {
        config.headers = {};
    }
    if (process.env.NODE_ENV === 'development') {
        // config.headers = { 'x-user-group': 'super-admin' };
        config.headers.authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJmb29iYXIiLCJuYW1lIjoiTWF4IE11c3Rlcm1hbm4iLCJjdHJ5IjoiQmVybGluIiwiZmFtaWx5X25hbWUiOiJNdXN0ZXJtYW5uIiwiZ2l2ZW5fbmFtZSI6Ik1heCIsImdyb3VwcyI6WyJVU0VSIl0sIm9pZCI6IjhkODgyYWIyLWNhYmYtNDA2ZC04NzM3LThiNzg0MzI5MTZiOCIsImlhdCI6MTY1NjU3MjkzMiwidGlkIjoiOTE2NzljOWEtOWFjNi00NDhlLTgyMDUtODk1Yjg5MDM4ZDc5IiwiZW1haWwiOiJtYXhAbXVzdGVybWFubi5kZSJ9.k-2iAI2NnVi5jd4o_y9MrFz4wlFHw3XEfWTUdkl2e-Y`;
    }
    config.headers.Accept = 'application/json';
    config.headers['X-WORKFORCE-WEB'] = 1;
    return config;
}

export const AXIOS_INSTANCE = Axios.create({
    baseURL: api,
    timeout: 5000,
});

AXIOS_INSTANCE.interceptors.request.use(authRequestInterceptor);
AXIOS_INSTANCE.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const message = error.response?.data?.message || error.message;
        console.error(message);

        return Promise.reject(message);
    }
);

export const customAxios = <T>(config: AxiosRequestConfig): Promise<T> => {
    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(({ data }) => data);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
    };

    return promise;
};

export const authInterceptor: [any?, string?, AxiosInstance?] = [
    undefined,
    api,
    AXIOS_INSTANCE,
];
