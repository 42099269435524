import React from 'react';
import {styled, Box} from '@mui/material'



const StyledBox = styled(Box)(
    ({theme}) => `
     border-radius:30px;
     color: ${theme.palette.error.main}
`
);

const ErrorPage = () => {
    return (<StyledBox component='h1'>Error to fetch the Data or </StyledBox>)
};

export default ErrorPage;
