import {PropsWithChildren, ReactNode} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter as Router} from 'react-router-dom';
import {ReactQueryDevtools} from "react-query/devtools";

export const AppProvider = ({children}: PropsWithChildren<ReactNode>): JSX.Element => {
    const queryClient = new QueryClient();
    return (
        <Router>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                {children}
            </QueryClientProvider>
        </Router>
    );
};
