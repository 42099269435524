/**
 * Generated by orval v6.9.2 🍺
 * Do not edit manually.
 * Workforce API
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from 'react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  HomepageResponse,
  GetHomepageParams
} from '../../models'
import { customAxios } from '../../../lib/axios'

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getHomepage = (
    params: GetHomepageParams,
 signal?: AbortSignal
) => {
      return customAxios<HomepageResponse>(
      {url: `/api/user/v1/screen/homepage`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetHomepageQueryKey = (params: GetHomepageParams,) => [`/api/user/v1/screen/homepage`, ...(params ? [params]: [])];

    
export type GetHomepageQueryResult = NonNullable<Awaited<ReturnType<typeof getHomepage>>>
export type GetHomepageQueryError = unknown

export const useGetHomepage = <TData = Awaited<ReturnType<typeof getHomepage>>, TError = unknown>(
 params: GetHomepageParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHomepage>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetHomepageQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHomepage>>> = ({ signal }) => getHomepage(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getHomepage>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

