import packageJson from '../../package.json';

export type Environment = {
  productName: string;
  version: string;
  build: string;
  release: string;
  environment: string;
  api: string;
  tenant: string;
};

export const baseEnvironment: Pick<Environment, 'productName' | 'version'> = {
  productName: 'Workforce',
  version: packageJson.version,
};
