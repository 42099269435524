import {Avatar, Container, Drawer, IconButton, Link, List, ListItem, styled} from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import {
    useState
} from "react";
import {useGetUser} from "../proxy/api/user-info-controller/user-info-controller";

const StyledUserName = styled('span')`
  height: 24px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 16px;
  display: block;
`;
const StyledEmail = styled('span')`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 16px;
`;
const DrawerMenu = () => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const user = useGetUser();
    return (
        <>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <Container sx={{marginLeft:'20'}}>
                    <Avatar sx={{margin:'16px'}}>IMG</Avatar>
                    <StyledUserName> {user.data?.name} </StyledUserName>
                    <StyledEmail> {user.data?.email} </StyledEmail>
                    <List sx={{width: '207px', flexDirection:'column'}}>
                        <ListItem sx={{height: '49px'}}>
                            <Link href='/home' color='secondary' sx={{
                                display: 'flex',
                                textDecoration: 'none',
                                "&:hover": {backgroundColor: "transparent", color: 'primary.main'}
                            }}>&nbsp;Home</Link>
                        </ListItem>
                        <ListItem sx={{height: '49px'}}>
                            <Link href='/profile' color='secondary' sx={{
                                display: 'flex',
                                textDecoration: 'none',
                                "&:hover": {backgroundColor: "transparent", color: 'primary.main'}
                            }}>&nbsp;Profile</Link>
                        </ListItem>
                        <ListItem sx={{height: '49px'}}>
                            <Link href='/settings' color='secondary' sx={{
                                display: 'flex',
                                textDecoration: 'none',
                                "&:hover": {backgroundColor: "transparent", color: 'primary.main'}
                            }}>
                                &nbsp;Settings</Link>
                        </ListItem>
                        <ListItem sx={{height: '49px', alignSelf:'end'}}>
                            <Link href='/services' color='secondary' sx={{
                                display: 'flex',
                                textDecoration: 'none',
                                "&:hover": {backgroundColor: "transparent", color: 'primary.main'}
                            }}>
                                &nbsp;All Services</Link>
                        </ListItem>
                    </List>
                    <List sx={{display: 'flex', height:'100%'}}>
                        <ListItem sx={{height: '49px', marginTop: 'auto'}}>
                            <Link href='/legalsAndImprint' color='secondary' sx={{
                                display: 'flex',
                                textDecoration: 'none',
                                "&:hover": {backgroundColor: "transparent", color: 'primary.main'}
                            }}>
                                &nbsp;Legas and Imprint </Link>
                        </ListItem>
                    </List>
                </Container>
            </Drawer>

            <IconButton sx={{"&:hover": {backgroundColor: "transparent"}, marginRight:'20px'}} onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon></MenuIcon>
            </IconButton>
        </>
    )
}
export default DrawerMenu
