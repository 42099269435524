import {Button, styled} from '@mui/material';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';


const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
`;

const LandingPage = () => {
    const {t} = useTranslation();
    useEffect(() => {
        document.title = t('title')
    }, [t])

    return (
        <Wrapper>
            <h1>{t('welcomeText')}</h1>
            <Button variant="contained" href="/home">{t('login')}</Button>
        </Wrapper>
    );
};

export default LandingPage;
