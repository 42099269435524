import {styled} from "@mui/material";

export type DynamicGridColumnSetting = {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
}

interface Props {
    columns: DynamicGridColumnSetting;
}

const DynamicGrid = styled('div')<Props>(({theme, columns}) => `
  display: grid;
  gap: 15px;
  color: ${theme.palette.success.main}
  ${theme.breakpoints.up('xs')} {
    grid-template-columns: repeat(${columns.xs}, 1fr);
  }
  ${theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(${columns.sm}, 1fr);
  }
  ${theme.breakpoints.up('md')} {
    grid-template-columns: repeat(${columns.md}, 1fr);
  }
  ${theme.breakpoints.up('lg')} {
    grid-template-columns: repeat(${columns.lg}, 1fr);
  }
  ${theme.breakpoints.up('xl')} {
    grid-template-columns: repeat(${columns.xl}, 1fr);
  }
`);

export default DynamicGrid;
