import {theme} from "../styles/themes";
import {TenantConfig} from "../proxy/models";
import {DynamicGridColumnSetting} from "../components/DynamicGrid";

const deviceClassifications = {
    xs: "EXTRA_SMALL",
    sm: "SMALL",
    md: "MEDIUM",
    lg: "LARGE",
    xl: "LARGE",
}

export const calculatedDeviceClassification = () => {
    const breakpoint = Object
        .entries(theme.breakpoints.values)
        .reverse()
        .find(breakpoint => window.innerWidth >= breakpoint[1]);

    return deviceClassifications[breakpoint ? breakpoint[0] : "LARGE"];
};

const columnByDeviceClassification = (tenantConfig: TenantConfig, deviceClassification: string): number => {
    return tenantConfig?.layoutSettings
        .find(item => item.deviceClassification === deviceClassification)?.columns ?? 12;
};

export const getColumns = (tenantConfig: TenantConfig): DynamicGridColumnSetting => ({
    xs: columnByDeviceClassification(tenantConfig, deviceClassifications.xs),
    sm: columnByDeviceClassification(tenantConfig, deviceClassifications.sm),
    md: columnByDeviceClassification(tenantConfig, deviceClassifications.md),
    lg: columnByDeviceClassification(tenantConfig, deviceClassifications.lg),
    xl: columnByDeviceClassification(tenantConfig, deviceClassifications.xl),
});
