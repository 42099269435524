import * as React from "react";
import {AppProvider} from "./providers";
import {AppRoutes} from "./routes";
import {Container, ThemeProvider, styled, CssBaseline } from '@mui/material'
import {theme} from './styles/themes'
import Header from '../src/components/Header'

const StyledContainer = styled(Container)`
  display: flex;
  vertical-align: top;
  justify-content: center;
`;

const App = () => {
    return (
        <AppProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Header/>
                <StyledContainer>
                    <AppRoutes/>
                </StyledContainer>
            </ThemeProvider>
        </AppProvider>

    )
}

export default App

