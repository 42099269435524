import {CircularProgress, Container, styled} from '@mui/material';
import React, {useState} from 'react';
import ErrorPage from "../components/ErrorPage";
import LinkWidget from "../components/LinkWidget";
import {useGetHomepage} from "../proxy/api/screen-controller/screen-controller";
import {useGetTenantConfig} from '../proxy/api/tenant-config-controller/tenant-config-controller'
import {calculatedDeviceClassification, getColumns} from "../utils/utils";
import DynamicGrid from "../components/DynamicGrid";
import {TenantConfig} from "../proxy/models";
import SecondaryHeader from "../components/SecondaryHeader";

const StyledContainer = styled(Container)`
  width: 65%; //must be 796px
  display: flex;
  flex-direction: column;
`;

const Home = () => {
    const [showActions, setShowActions] = useState(false);
    const tenantConfig = useGetTenantConfig({platform: 'DESKTOP'});
    const columns = getColumns(tenantConfig.data as TenantConfig);
    const homepage = useGetHomepage({deviceClassification: calculatedDeviceClassification()});

    return (tenantConfig.isLoading || homepage.isLoading)
        ? <CircularProgress />
        : (tenantConfig.isError || homepage.isError) ? <ErrorPage></ErrorPage> :
            <StyledContainer disableGutters>
                <SecondaryHeader showActions={showActions} setShowActions={setShowActions}/>
                <DynamicGrid columns={columns}>
                    {homepage.data?.widgets.map((widget) => {
                            switch (widget.type) {
                                case 'link-widget-v1':
                                    return <LinkWidget
                                        key={widget.id}
                                        width={widget.width}
                                        height={widget.height}
                                        editmode={showActions}
                                        config={widget.config}
                                    />;
                                default:
                                    return null;
                            }
                        }
                    )}
                </DynamicGrid>
            </StyledContainer>
}

export default Home;
